import {FormGroup} from "@angular/forms";
import {Injectable} from "@angular/core";
import {Router} from "@angular/router";

import {User} from "../model/User";

import {Response, Tools} from "../tools";
import {CryptUtils} from "../crypt_utils";
import {BehaviorSubject} from "rxjs";
import {UserSettings} from "../model/UserSettings";
import {NetworkService} from "./network";
import {SubscriptionService} from "./subscription";
import {FileInfo} from "../model/FileInfo";
import {db} from "../../../db/db";

@Injectable({
  providedIn: "root"
})
export class AuthService {
  private user: User | null = null;
  private isLoggedInSubject: BehaviorSubject<boolean>;
  private isDecryptedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private secretKey?: CryptoKey;

  redirectUrl?: string;

  constructor(private router: Router, private networkService: NetworkService, private subscriptionService: SubscriptionService) {
    try {
      this.user = JSON.parse(localStorage.getItem("user")!);
    } catch (e) {
    }

    this.isLoggedInSubject = new BehaviorSubject<boolean>(this.user?.user_id != undefined);

    this.networkService.logout.subscribe(async logout => {
      if (logout) {
        this.networkService.logout.next(false);

        await this.logout();
      }
    })
  }

  get isLoggedIn(): boolean {
    return this.isLoggedInSubject.value;
  }

  get isDecrypted(): boolean {
    return this.isDecryptedSubject.value;
  }

  getUserId(): string {
    return (this.user) ? this.user.user_id : "";
  }

  setUsername(username: string): void {
    if (this.user) {
      this.user.username = username;
      localStorage.setItem("user", JSON.stringify(this.user));
    }
  }

  setUser(user: User): void {
    if (user && user.user_id) {
      this.user = user;
      localStorage.setItem("user", JSON.stringify(user));
    }
  }

  async setSettings(settings: UserSettings): Promise<void> {
    if (this.user) {
      this.user.settings = settings;

      const user = JSON.parse(localStorage.getItem("user")!);
      user.settings = await CryptUtils.encryptData(JSON.stringify(settings), this.secretKey!);

      localStorage.setItem("user", JSON.stringify(user));
    }
  }

  async setImage(image: FileInfo): Promise<void> {
    if (this.user) {
      this.user.picture = image;

      const user = JSON.parse(localStorage.getItem("user")!);
      user.picture = await CryptUtils.encryptData(JSON.stringify(image), this.secretKey!);

      localStorage.setItem("user", JSON.stringify(user));
    }
  }

  getSettings(): UserSettings {
    return (this.user) ? this.user.settings as UserSettings : {};
  }

  getImage(): FileInfo {
    return (this.user) ? this.user.picture as FileInfo : {} as FileInfo;
  }

  hasDarkMode(): boolean {
    return this.getSettings().dark_mode ?? localStorage.getItem("dark_mode") !== "";
  }

  getUsername(): string {
    return (this.user) ? this.user.username : "";
  }

  getPrivateKey(): CryptoKey | null {
    if (this.user) {
      return this.user.private_key as CryptoKey;
    }
    return null;
  }

  getSignPrivateKey(): CryptoKey | null {
    if (this.user) {
      return this.user.sign_private_key as CryptoKey;
    }
    return null;
  }

  getSecretKey(): CryptoKey | null {
    if (this.secretKey) {
      return this.secretKey;
    }
    return null;
  }

  async clearData(): Promise<void> {
    try {
      await db.connection.dropDb().catch(e => {
      });
    } catch (e) {}

    await this.logout(true);
  }

  async logout(clear = false): Promise<void> {
    localStorage.setItem("logging_out", "true");

    await db.connection.remove({
      from: "users",
      where: {
        user_id: this.getUserId()
      }
    });

    this.user = null;
    localStorage.removeItem("user");
    localStorage.removeItem("dark_mode");
    localStorage.removeItem("pictureTimestamp");
    localStorage.removeItem("lastSubscription");

    this.isDecryptedSubject.next(false);
    this.isLoggedInSubject.next(false);

    this.isLoggedInSubject.next(false);
    this.isDecryptedSubject.next(false);

    await this.networkService.request("DELETE", "/subscription");
    await this.networkService.request("POST", "/auth/logout");

    localStorage.removeItem("logging_out");

    if (clear && "serviceWorker" in navigator && navigator.serviceWorker.controller) {
      navigator.serviceWorker.controller.postMessage({
        command: "clearCache",
      });
    } else document.location.reload();
  }

  login(formGroup: FormGroup): void {
    this.networkService.request("POST", "/auth/salt", JSON.stringify({username: formGroup.value.username})).then(async response => {
      if (response.status === "success") {
        let salt = CryptUtils.base64ToArray(response.data.salt);
        this.secretKey = await CryptUtils.passwordToSecretKey(formGroup.value.password, salt);

        let formData = structuredClone(formGroup.value) as Record<string, any>;
        formData["password"] = await CryptUtils.hashSecretKey(this.secretKey);

        this.networkService.request("POST", "/auth/login", JSON.stringify(formData)).then(async response => {
          if (response.status === "success") this.processUser(response, formGroup.value.password);
        });
      }
    });
  }

  /**
   * Registriert einen neuen Benutzer und speichert die Benutzerdaten auf dem Server.
   * Generiert einen symmetrischen Schlüssel aus dem Passwort des Benutzers.
   * Generiert ein Schlüsselpaar, verschlüsselt den privaten Schlüssel mit dem symmetrischen Schlüssel des Benutzers.
   * Speichert die Daten, verschlüsselt auf dem Server.
   */
  async register(formGroup: FormGroup): Promise<void> {
    if (!formGroup.valid) {
      Tools.showMessage("Programmmanipulation festgestellt", "error");
      return;
    }

    const salt = crypto.getRandomValues(new Uint8Array(16));
    this.secretKey = await CryptUtils.passwordToSecretKey(formGroup.value.password, salt);
    const password = await CryptUtils.hashSecretKey(this.secretKey);

    const keyPair = await CryptUtils.generateKeyPair();
    const keySignPair = await CryptUtils.generateSignKeyPair();

    const private_key = await CryptUtils.encryptPrivateKey(keyPair.privateKey, this.secretKey);
    const sign_private_key = await CryptUtils.encryptPrivateKey(keySignPair.privateKey, this.secretKey);

    const formData = structuredClone(formGroup.value) as Record<string, any>;
    delete formData["password_repeat"];

    formData["password"] = password;
    formData["salt"] = CryptUtils.arrayToBase64(salt);

    formData["private_key"] = private_key;
    formData["public_key"] = await CryptUtils.exportPublicKey(keyPair.publicKey);

    formData["sign_private_key"] = sign_private_key;
    formData["sign_public_key"] = await CryptUtils.exportPublicKey(keySignPair.publicKey);

    this.networkService.request("POST", "/auth/register", JSON.stringify(formData)).then(async response => {
      if (response.status == "success") this.processUser(response, formGroup.value.password);
    });
  }

  // TODO: Remove this on the next update
  async checkSignKeys() {
    let error = false;
    try {
      this.user!.sign_public_key = await CryptUtils.importSignPublicKey(this.user!.sign_public_key as string);

      if (this.user!.sign_public_key === {} as CryptoKey) {
        error = true;
      }
    } catch (e) {
      error = true;
    }

    if (error || !await CryptUtils.areKeysAPair(this.user!.sign_private_key as CryptoKey, this.user!.sign_public_key as CryptoKey)) {
      const keySignPair = await CryptUtils.generateSignKeyPair();
      const sign_public_key = await CryptUtils.exportPublicKey(keySignPair.publicKey);
      const sign_private_key = await CryptUtils.encryptPrivateKey(keySignPair.privateKey, this.secretKey!);

      this.networkService.request("PATCH", "/auth/account/sign", JSON.stringify({
        sign_private_key: sign_private_key,
        sign_public_key: sign_public_key
      })).then(response => {
        if (response.status === "success") {
          const user = JSON.parse(localStorage.getItem("user")!);
          user.sign_private_key = sign_private_key;
          user.sign_public_key = sign_public_key;

          localStorage.setItem("user", JSON.stringify(user));

          this.user!.sign_private_key = keySignPair.privateKey;
        }
      });
    }
  }

  async decrypt(formGroup: FormGroup) {
    if (await CryptUtils.decryptUser(this.user!, formGroup.value.password)) {
      this.isDecryptedSubject.next(true);
      this.isLoggedInSubject.next(true);

      this.secretKey = await CryptUtils.passwordToSecretKey(formGroup.value.password, this.user!.salt as Uint8Array);
      this.checkSignKeys();

      if (this.redirectUrl) {
        this.router.navigateByUrl(this.redirectUrl);
        this.redirectUrl = undefined;
      } else {
        this.router.navigateByUrl("/");
      }

      this.subscriptionService.subscribe();
    } else {
      Tools.showMessage("Entschlüsselung fehlgeschlagen", "error");
    }
  }

  private async processUser(response: Response, password: string) {
    this.user = response.data as User;

    localStorage.setItem("user", JSON.stringify(this.user));
    await CryptUtils.decryptUser(this.user, password);

    this.checkSignKeys();

    this.isDecryptedSubject.next(true);
    this.isLoggedInSubject.next(true);

    this.router.navigateByUrl("/");

    this.subscriptionService.subscribe();
  }
}
