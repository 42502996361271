<div class="picture ratio ratio-1x1 rounded-circle">
  <ng-container *ngIf="this.member; else userPicture">
    <ng-container *ngIf="this.member.chat!.is_group; else memberPicture">
      <ng-container *ngIf="this.member.chat!.picture; else defaultGroupPicture">
        <img alt="{{this.member.chat!.name}}" class="img-cover chat"
             [src]="Tools.asFileInfo(this.member.chat!.picture).data! | checkXSS">
      </ng-container>
      <ng-template #defaultGroupPicture>
        <img alt="{{this.member.chat!.name}}" class="img-cover default-chat" src="assets/img/group-light.png">
      </ng-template>
    </ng-container>
    <ng-template #memberPicture>
      <ng-container *ngIf="this.member.user!.picture; else defaultMemberPicture">
        <img alt="{{this.member.user!.username}}" class="img-cover member"
             [src]="Tools.asFileInfo(this.member.user!.picture).data! | checkXSS">
      </ng-container>
      <ng-template #defaultMemberPicture>
        <img alt="{{this.member.user!.username}}" class="img-cover default-member" src="assets/img/default-light.png">
      </ng-template>
    </ng-template>
  </ng-container>
  <ng-template #userPicture>
    <ng-container *ngIf="this.user">
      <ng-container *ngIf="this.user && this.user.picture; else defaultUserPicture">
        <img alt="{{this.user.username}}" class="img-cover user"
             [src]="Tools.asFileInfo(this.user.picture).data! | checkXSS">
      </ng-container>
      <ng-template #defaultUserPicture>
        <img alt="{{this.user.username}}" class="img-cover default-user" src="assets/img/default-light.png">
      </ng-template>
    </ng-container>
  </ng-template>
</div>
