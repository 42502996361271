import {ChangeDetectorRef, Component} from '@angular/core';
import {PopstateService} from "../../assets/js/service/popstate";
import {HomeService} from "../../assets/js/service/home";
import {MenuItem} from "../../assets/js/interface/menu_item";
import {ChatService} from "../../assets/js/service/chat";
import {ScreenSizeService} from "../../assets/js/service/screen";
import {ComponentService} from "../../assets/js/service/component";

@Component({
  selector: 'app-archive',
  templateUrl: './archive.component.html',
  styleUrl: './archive.component.scss'
})
export class ArchiveComponent {
  open_state: boolean = false;

  contextMenuItems: MenuItem[] = [
    {label: "Aktivieren", action: (chat_id) => this.chatService.enableChat(chat_id)},
  ];

  protected loading: boolean = false;

  constructor(protected componentService: ComponentService, protected screenService: ScreenSizeService, protected homeService: HomeService, protected chatService: ChatService, private popstateService: PopstateService, private cdr: ChangeDetectorRef) {
  }

  open() {
    this.popstateService.addState("archive", () => {
      this.open_state = false;

      this.cdr.detectChanges();

      return true;
    });

    this.open_state = true;
  }

  close() {
    this.open_state = false;
  }
}
