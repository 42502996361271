import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs";
import {BreakpointObserver, BreakpointState} from "@angular/cdk/layout";
import {HomeComponent} from "../../../app/home/home.component";
import {SearchComponent} from "../../../app/search/search.component";
import {ChatComponent} from "../../../app/chat/chat.component";

@Injectable({
  providedIn: 'root',
})
export class ComponentService {
  components: { [component_name: string]: any } = {};

  constructor() {
  }

  addComponent(component: any): void {
    this.components[component.constructor.name] = component;
  }

  getHome(): null|HomeComponent {
    if (typeof this.components["HomeComponent"] !== "undefined") {
      return this.components["HomeComponent"] as HomeComponent;
    }

    return null;
  }

  getChat(): null|ChatComponent {
    if (typeof this.components["ChatComponent"] !== "undefined") {
      return this.components["ChatComponent"] as ChatComponent
    }

    return null;
  }

  getSearch(): null|SearchComponent {
    if (typeof this.components["SearchComponent"] !== "undefined") {
      return this.components["SearchComponent"] as SearchComponent;
    }

    return null;
  }
}
