import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  Output,
  ViewChild
} from '@angular/core';
import {MenuAction} from "../../../assets/js/interface/menu_action";
import {MenuItem} from "../../../assets/js/interface/menu_item";
import {HomeService} from "../../../assets/js/service/home";
import {ContextmenuService} from "../../../assets/js/service/contextmenu";

@Component({
  selector: 'app-contextmenu',
  templateUrl: './contextmenu.component.html',
  styleUrls: ['./contextmenu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContextmenuComponent implements AfterViewInit, OnDestroy {
  @Input() menuItems: MenuItem[] = [];

  private intersectionObserver?: IntersectionObserver;

  private touchStart: number = 0;
  private touchMoved: boolean = false;

  timeout: any;
  open_timestamp: number = 0;
  visible: boolean = false;
  position = {x: 0, y: 0};

  protected isVisible: boolean = false;

  @ViewChild("contextMenu")
  private contextMenu!: ElementRef;
  private data?: object = [];
  protected parameters: any[] = [];

  constructor(private elementRef: ElementRef, private homeService: HomeService, private contextmenuService: ContextmenuService, private cdr: ChangeDetectorRef) {
  }

  ngAfterViewInit(): void {
    this.initIntersectionObserver();
  }

  ngOnDestroy() {
    if (this.intersectionObserver) {
      this.intersectionObserver.disconnect();
    }
  }

  checkCondition(condition: any): boolean {
    if (typeof condition === "undefined" || condition.length > this.parameters.length) {
      return true;
    }

    const result = condition(...this.parameters);

    if (typeof result === "undefined") {
      return true;
    }

    return result;
  }

  @HostListener("document:click", ["$event"])
  clickOut(event: Event) {
    if (this.open_timestamp + 500 > Date.now() || !this.visible) {
      return;
    }

    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.closeContextMenu();
    }
  }

  private findScrollableElements(): NodeListOf<Element> {
    const allElements = document.querySelectorAll('*');
    const scrollableElements: any = [];

    allElements.forEach(element => {
      if (element.scrollHeight !== element.clientHeight) {
        scrollableElements.push(element);
      }
    });

    return scrollableElements;
  }

  handleTouchStart(event: TouchEvent, data: any = {}, ...parameters: any): void {
    this.homeService.isTouching = true;

    this.touchStart = Date.now();
    this.touchMoved = false;

    if (event.touches.length > 1) {
      clearTimeout(this.timeout);
      return;
    }

    this.timeout = setTimeout(() => {
      this.openContextMenu(event, data, ...parameters);
      this.cdr.detectChanges();
    }, 500);

    this.cdr.detectChanges();
  }

  handleTouchMove(): void {
    this.touchMoved = true;

    clearTimeout(this.timeout);

    this.cdr.detectChanges();
  }

  handleTouchEnd(event: TouchEvent): void {
    if (event.touches.length > 1) {
      return;
    }

    if (Date.now() - this.touchStart < 500) {
      clearTimeout(this.timeout);

      if (!this.touchMoved) {
        event.target!.dispatchEvent(new Event("click"));
      }
    } else {
      event.preventDefault();
    }

    this.homeService.isTouching = false;

    this.cdr.detectChanges();
  }

  closeContextMenu() {
    document.querySelectorAll(".disable-scroll").forEach(element => {
      element.classList.remove("disable-scroll");
    });

    this.visible = false;
    this.contextmenuService.element = undefined;
    this.parameters = [];

    this.cdr.detectChanges();
  }

  openContextMenu(event: MouseEvent | TouchEvent, data: any = {}, ...parameters: any) {
    event.preventDefault();

    if (this.contextmenuService.element) {
      this.contextmenuService.element.visible = false;
    }

    this.contextmenuService.element = this;

    this.open_timestamp = Date.now();
    this.findScrollableElements().forEach(element => {
      element.classList.add("disable-scroll");
    });

    // Determine the position based on the type of event
    let posX: number = 0;
    let posY: number = 0;

    if (event instanceof MouseEvent) {
      posX = event.clientX;
      posY = event.clientY;
    } else if (event instanceof TouchEvent) {
      if (event.changedTouches.length > 0) {
        posX = event.changedTouches[0].clientX;
        posY = event.changedTouches[0].clientY;
      } else if (event.touches.length > 0) {
        posX = event.touches[0].clientX;
        posY = event.touches[0].clientY;
      } else {
        return;
      }
    }

    this.position.x = posX;
    this.position.y = posY;

    setTimeout(() => {
      const rect = this.contextMenu.nativeElement.getBoundingClientRect();
      const maxWidth = window.innerWidth - rect.width;
      const maxHeight = window.innerHeight - rect.height;

      if (posX + rect.width > window.innerWidth) {
        this.position.x = maxWidth;
      }
      if (posY + rect.height > window.innerHeight) {
        this.position.y = maxHeight;
      }

      this.cdr.detectChanges();
    });

    this.data = data;
    this.parameters = parameters;

    this.visible = true;

    this.cdr.detectChanges();
  }


  onMenuItemSelected(menuAction: MenuAction) {
    this.closeContextMenu();

    menuAction.action(...Object.values({...this.data, ...menuAction.data}));
  }

  private initIntersectionObserver(): void {
    this.intersectionObserver = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.target === this.contextMenu.nativeElement) {
          this.isVisible = entry.isIntersecting;
          this.cdr.detectChanges();
        }
      });
    }, {threshold: [0.1]});

    if (this.contextMenu && this.contextMenu.nativeElement) {
      this.intersectionObserver.observe(this.contextMenu.nativeElement);
    }
  }
}
