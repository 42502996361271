<div id="chat-component" [class.show]="this.chatService.chatId && !info.open_state">
  <nav class="navbar navbar-expand-lg z-3 w-100 top-0" [class.d-none]="info.open_state">
    <div *ngIf="this.member" class="container-fluid">
      <div class="d-flex flex-grow-1">
        <i (click)="this.unloadChat()" class="bi bi-arrow-left navbar-brand px-2 me-2"></i>
        <div class="w-40-px ratio ratio-1x1 rounded-circle overflow-hidden me-3">
          <app-profile-picture [member]="this.member"></app-profile-picture>
        </div>
        <a class="navbar-brand flex-grow-1"
           (click)="info.open()">{{ this.member.chat!.name ?? this.member.user!.username }}</a>
      </div>
      <button aria-controls="action-menu" aria-expanded="false" aria-label="Toggle navigation"
              class="navbar-toggler shadow-none" data-bs-toggle="dropdown" id="action-menu" type="button">
        <i class="bi bi-three-dots-vertical"></i>
      </button>
      <ul aria-labelledby="action-menu" class="dropdown-menu dropdown-menu-end">
        <div *ngIf="!this.member.chat!.is_group">
          <li *ngIf="!this.chatService.blocked">
            <a (click)="this.chatService.blockUser(this.chatService.chatId)" class="dropdown-item">Blockieren</a>
          </li>
          <li *ngIf="this.chatService.blocked">
            <a (click)="this.chatService.unblockUser(this.chatService.chatId)" class="dropdown-item">Entsperren</a>
          </li>
        </div>
        <li><a (click)="this.setMessageTime()" class="dropdown-item">Nachrichten Zeit</a></li>
        <li><a (click)="this.search()" class="dropdown-item">Suchen</a></li>
        <li *ngIf="this.member.chat!.creator_id === this.authService.getUserId()">
          <a (click)="this.addUser()" class="dropdown-item">Einladen</a>
        </li>

        <li *ngIf="!this.member.chat!.creator_id || this.member.chat!.creator_id === this.authService.getUserId()">
          <a (click)="this.deleteChat()" class="dropdown-item">Löschen</a>
        </li>
        <li *ngIf="!!this.member.chat!.creator_id && this.member.chat!.creator_id !== this.authService.getUserId()">
          <a (click)="this.leaveChat()" class="dropdown-item">Verlassen</a>
        </li>
      </ul>
    </div>
  </nav>

  <ng-container *ngIf="this.chatService.chatId">
    <div class="message-reference z-2" *ngIf="this.chatService.reference_message">
      <i class="bi bi-x z-2" (click)="this.closeReference()"></i>
      <div class="date-divider">
        <div>{{ this.chatService.reference_message.timestamp * 1000 | date: "dd.MM.yyyy" }}</div>
      </div>
      <app-message [message]="this.chatService.reference_message"></app-message>
    </div>
    <div class="d-flex flex-column h-100-56-px" id="chat-screen">
      <app-file-viewer *ngIf="this.chatService.open_message"></app-file-viewer>
      <div #messagesContainer (scroll)="this.onScroll()" id="messages">
        <ng-container
          *ngIf="!this.loading && !this.unloading">
          <ng-container
            *ngFor="let message of this.chatService.sorted_messages; trackBy: this.trackByMessageId; let index = index">
            <div *ngIf="!isSameDay(index)" class="date-divider">
              <div>{{ message.timestamp * 1000 | date: "dd.MM.yyyy" }}</div>
            </div>
            <app-message [message]="message"></app-message>
          </ng-container>
        </ng-container>
        <button #scrollToBottom id="scrollButton"
                (click)="messagesContainer.scrollTop = messagesContainer.scrollHeight">
          <i class="bi bi-arrow-down"></i>
        </button>
      </div>
      <div class="m-0" id="messageBox">
        <div id="file-preview" class="d-flex overflow-y-auto">
          @for (fileInfo of this.selected_files; track fileInfo; let index = $index) {
            <div class="file">
              <i class="bi bi-x" (click)="this.removeFile(index)"></i>
              <img *ngIf="FileUtils.image_types.includes(fileInfo.type)"
                   [src]="fileInfo.data" alt="Image">
              <video *ngIf="FileUtils.video_types.includes(fileInfo.type)"
                     [src]="fileInfo.data" autoplay loop muted></video>
              <img *ngIf="FileUtils.audio_types.includes(fileInfo.type)"
                   [src]="FileUtils.icon_list[fileInfo.type]" class="icon" alt="Audio">
              <img *ngIf="FileUtils.document_types.includes(fileInfo.type)"
                   [src]="FileUtils.icon_list[fileInfo.type]" class="icon" alt="Document">
              <img *ngIf="!FileUtils.all_types.includes(fileInfo.type)"
                   [src]="FileUtils.icon_list['file']" class="icon" alt="File">
            </div>
          }
        </div>
        <div #waveform id="waveform" [class.show]="this.recording"></div>
        <div class="d-flex">
          <div id="messageInput" class="d-flex" (keydown)="this.keydownEvent($event)" (paste)="this.pasteEvent($event)"
               (drop)="this.dropFiles($event)">
            <button
              (click)="this.selectFile()"
              class="file-selector">
              <i class="bi bi-files"></i>
            </button>
            <div #messageInput
                 (keyup.enter)="this.onEnter()"
                 (click)="this.checkScroll()"
                 class="form-control shadow-none" autocomplete="off" rows="2" contenteditable="true"><br></div>
          </div>
          @switch (true) {
            @case (!this.recording && (this.messageInput.textContent!.trim() !== "" || this.selected_files.length > 0)) {
              <button
                (click)="this.enterMessage()"
                class="btn btn-primary" id="sendButton" type="button">
                <i class="bi bi-send-fill"></i>
              </button>
            }
            @case (!this.recording) {
              <button
                (click)="this.startRecording()"
                class="btn btn-primary" id="startRecordButton" type="button">
                <i class="bi bi-mic-fill"></i>
              </button>
            }
            @case (this.recording) {
              <button
                (click)="this.stopRecording()"
                class="btn btn-primary" id="stopRecordButton" type="button">
                <i class="bi bi-mic-mute-fill"></i>
              </button>
            }
          }
        </div>
      </div>
    </div>
  </ng-container>
</div>

<app-info #info></app-info>
